import React from 'react';

import Lottie from 'react-lottie';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as animationData from '../lotties/404-error.json';

const NotFoundPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="py-20 max-w-lg mx-auto">
        <Lottie options={defaultOptions} />
      </div>
      <div className="text-center">
        <h1 className="text-5xl">404: Not Found</h1>
        <p className="text-xl">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
